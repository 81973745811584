import React from "react";

const PaginaAmbasador = () => {
  return (
    <div className="min-h-screen bg-white rounded-lg text-gray-800">
      {/* Imaginea */}
      <div className="w-full">
        <img
          src="/isticioaia-prezentare.jpg"
          alt="Viorel Isticioaia-Budura"
          className="w-full h-auto rounded-t-lg object-cover"
        />
      </div>

      {/* Conținutul */}
      <div className="max-w-4xl mx-auto px-6 py-8">
        {/* Titlul */}
        <h1 className="text-3xl font-bold text-gray-900">
          Amb. Viorel Isticioaia-Budura
        </h1>
        <p className="text-lg text-blue-600 font-semibold">
          Președinte de onoare CCIRC
        </p>

        {/* Descrierea */}
        <div className="mt-4 space-y-3 text-justify">
          <p>
            Acțiunea colectivă și convergentă, întemeiată pe experiență,
            profesionalism și împărtășirea intereselor, generează beneficii
            celor care se asociază!
          </p>
          <p>
            Această aserțiune este ilustrată substanțial, extins și convingător
            de existența, funcționarea și rezultatele Camerei de Comerț și
            Industrie România-China.
          </p>
          <p>
            Într-un context național și internațional în continuă și dinamică
            evoluție, avem nevoie cu toții de o zonă în care să regăsim
            certitudini, încredere și expertiză. M- am bucurat să constat cum
            CCIRC reprezintă un asemenea spațiu ce furnizează membrilor,
            oamenilor de afaceri și, în general, celor interesați de relații și
            schimburi cu China, servicii și consultanță ce răspund unor asemenea
            așteptări.
          </p>
          <p>
            Ascensiunea globală a Chinei ca putere economico-comercială și
            performer tehnologic, procesul tot mai complex de reformă și
            reajustare a politicilor macroeconomice proprii, evoluțiile rapide
            și diversificarea cerințelor pieței, toate impun un efort
            suplimentar de monitorizare, înțelegere și aprofundare a cunoașterii
            acestora de către cei ce doresc să fie parteneri cu oamenii de
            afaceri chinezi.
          </p>
          <p>
            CCIRC s-a afirmat consecvent drept unrecipient al expertizei și un
            promotor flexibil în căutarea celor mai adecvate și eficiente
            strategii de dialog, comunicare și relaționare. Traversând cu
            pozitivă obstinație perioade dificile, așa cum s-a consumat cea a
            pandemiei de COVID 19, și-a menținut focalizarea, cu realism și
            pragmatism, pe explorarea oportunităților, a evenimentelor
            ocazionale sau periodice de promovare și a cultivat cu fidelitate
            activismul și loialitatea membrilor săi.
          </p>
          <p>
            Conducerii, asociaților și partenerilor români și chinezi, li se
            cuvine tuturor un sincer omagiu, felicitări și o susținere continuă
            meritorie pentru promovarea multiplă și benefică a prieteniei
            tradiționale și colaborării dintre România și Republica Populară
            Chineză!
          </p>
          <p className="font-semibold text-lg">
            Viorel Isticioaia-Budura,
            <br /> Ambasador de carieră,
            <br /> Președinte de onoare
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaginaAmbasador;
