import { Route, Routes } from "react-router-dom";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Layout from "./components/Layout";
import { UserContextProvider } from "./UserContext";
import PostPage from "./pages/PostPage";
import EditBlogPost from "./pages/EditBlogPost";
import Protected from "./components/Protected";
import AdminPanel from "./pages/AdminPanel";
import PaginaInLucru from "./components/PaginaInLucru";
import Contact from "./pages/Contact";
import Page404 from "./pages/Page404";
import CreatePost from "./pages/CreatePost";
import StudiiDocumentare from "./pages/StudiiDocumentare";
import Prezentare from "./pages/Prezentare";
import Parteneriate from "./pages/Parteneriate";
import ChinaAgenda from "./pages/ChinaAgenda";
import CerereAderare from "./pages/CerereAderare";
import ContractAdeziune from "./pages/ContractAdeziune";
import FisaCompaniei from "./pages/FisaCompaniei";
import FisaCompanii from "./pages/FisaCompanii";
import MandatReprezenatare from "./pages/MandatReprezenatare";
import FacilitatiA from "./pages/FacilitatiA";
import FacilitatiB from "./pages/FacilitatiB";
import FacilitatiC from "./pages/FacilitatiC";
import FacilitatiPrezentare from "./pages/FacilitatiPrezentare";
import CatalogServiciiAfaceri from "./components/CatalogServiciiAfaceri";
import CatalogServiciiLogistica from "./components/CatalogServiciiLogistica";
import SustineCCIRC from "./pages/SustineCCIRC";
import FilialeRO from "./pages/FilialeRO";
import ReprezentanteCH from "./pages/ReprezentanteCH";
import PaginaAmbasador from "./pages/PaginaAmbasador";

function App() {
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Page404 />} />
        <Route element={<Protected />}>
          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/create" element={<CreatePost />} />
          <Route path="/edit/:id" element={<EditBlogPost />} />
        </Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/blog/:id" element={<PostPage />} />
          <Route path="/prezentare" element={<Prezentare />} />
          <Route path="/echipa" element={<PaginaInLucru />} />
          <Route path="/agenda" element={<PaginaInLucru />} />
          <Route path="/parteneriate" element={<Parteneriate />} />
          <Route
            path="/facilitati-actionar-romanesc"
            element={<FacilitatiA />}
          />
          <Route path="/filiala-romania" element={<FilialeRO />} />
          <Route path="/reprezentante-china" element={<ReprezentanteCH />} />
          <Route path="/membri-de-onoare" element={<PaginaInLucru />} />
          <Route path="/fisa-companiei" element={<FisaCompaniei />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/studii-documentare" element={<StudiiDocumentare />} />
          <Route path="/relatii-bilaterale" element={<PaginaInLucru />} />
          <Route path="/china-agenda" element={<ChinaAgenda />} />
          <Route path="/cerere-aderare" element={<CerereAderare />} />
          <Route
            path="/contract-adeziune-membru"
            element={<ContractAdeziune />}
          />
          <Route path="/fisa-companii" element={<FisaCompanii />} />
          <Route
            path="/mandat-de-reprezentare"
            element={<MandatReprezenatare />}
          />
          <Route
            path="/facilitati-actionar-chinezesc"
            element={<FacilitatiB />}
          />
          <Route
            path="/facilitati-companii-chineze"
            element={<FacilitatiC />}
          />
          <Route
            path="/prezentare-facilitati"
            element={<FacilitatiPrezentare />}
          />
          <Route
            path="/catalog-servicii-afaceri-companii"
            element={<CatalogServiciiAfaceri />}
          />
          <Route
            path="/catalog-servicii-logistica"
            element={<CatalogServiciiLogistica />}
          />
          <Route path="/membri" element={<PaginaInLucru />} />
          <Route path="/sustine-ccirc" element={<SustineCCIRC />} />
          <Route
            path="/ambasador-viorel-isticioaia"
            element={<PaginaAmbasador />}
          />
        </Route>
      </Routes>
    </UserContextProvider>
  );
}

export default App;
