import React from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

export default function Prezentare() {
  const { t } = useTranslation();
  return (
    <div className="bg-white rounded-lg p-8 space-y-8">
      <Helmet>
        <title>Prezentare</title>
        <meta
          name="description"
          content="Prezentare Camera de Comert si Industrie Romania - China"
        />
        <meta
          name="keywords"
          content="prezentare, camera de comert si industrie, romania, china, ccir, ccircoffice, ccroch"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      {/* Titlu principal */}
      <h1 className="text-3xl font-bold text-center text-[var(--primary-color)]">
        {t("camera_de_comert_si_industrie_romania_china")}
      </h1>

      {/* Secțiunea de introducere */}
      <section className="space-y-4">
        <h2 className="text-2xl font-semibold text-gray-800">
          {t("bine_ai_venit")}
        </h2>
        <p className="text-gray-600">{t("prezentare_p1")}</p>
        <p className="text-gray-600">{t("prezentare_p2")}</p>
      </section>
      <a
        href="/prezentare.pptx" // Link către fișierul din folderul public
        download // Atribut pentru descărcare
        className="px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg shadow-md flex w-fit gap-2 ml-auto hover:bg-[var(--primary-hover)] transition"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
          />
        </svg>
        {t("descarca_brosura")}
      </a>

      {/* Card-uri pentru detalii cheie */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Card Identitate */}
        <div className="card bg-white shadow-lg p-4 border border-gray-200">
          <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-2">
            {t("identitate")}
          </h3>
          <p className="text-gray-600">{t("prezentare_p3")}</p>
        </div>

        {/* Card Misiune */}
        <div className="card bg-white shadow-lg p-4 border border-gray-200">
          <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-2">
            Misiune
          </h3>
          <p className="text-gray-600">{t("prezentare_p4")}</p>
        </div>
      </div>

      <section className="space-y-4">
        {/* Titlu secțiune */}
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          {t("obiective_institutionale")}
        </h2>

        {/* Imagine Landscape */}
        <div className="w-full h-64">
          <img
            src="/obiective.png"
            alt="Obiective CCIRC"
            className="w-full h-full object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Lista de obiective */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8 bg-gray-50 p-8 rounded-lg shadow-md">
          <div className="flex items-start gap-4">
            <span className="text-[var(--primary-color)] font-bold text-2xl">
              •
            </span>
            <p className="text-gray-700">{t("obiectiv_1")}</p>
          </div>
          <div className="flex items-start gap-4">
            <span className="text-[var(--primary-color)] font-bold text-2xl">
              •
            </span>
            <p className="text-gray-700">{t("obiectiv_2")}</p>
          </div>
          <div className="flex items-start gap-4">
            <span className="text-[var(--primary-color)] font-bold text-2xl">
              •
            </span>
            <p className="text-gray-700">{t("obiectiv_3")}</p>
          </div>
          <div className="flex items-start gap-4">
            <span className="text-[var(--primary-color)] font-bold text-2xl">
              •
            </span>
            <p className="text-gray-700">{t("obiectiv_4")}</p>
          </div>
        </div>
      </section>

      <section className="space-y-4">
        <h2 className="text-2xl font-semibold text-gray-800">
          {t("sediul_central")}
        </h2>
        <p className="text-gray-600">{t("prezentare_p5")}</p>
        {/* Imagine și detalii despre sediu */}
        <div className="flex flex-col md:flex-row gap-6">
          <img
            src="/cladire.jpg"
            alt="Sediul central al CCIRC"
            className="w-full md:w-1/2 rounded-lg shadow-md"
          />
          <div className="space-y-4 self-center">
            <p className="text-gray-600">
              {t("suprafata")}: <strong>{t("mp_250")}</strong>{" "}
              {t("suprafata_p1")}
            </p>
            <p className="text-gray-600">
              {t("prezentare_p6")}: <strong>15</strong>
            </p>
          </div>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {/* Imagini birouri (8 imagini) */}
          <img
            src="/birou1.jpg"
            alt="Birou 1"
            className="rounded-lg shadow-md"
          />
          <img
            src="/birou2.jpg"
            alt="Birou 2"
            className="rounded-lg shadow-md"
          />
          <img
            src="/birou3.jpg"
            alt="Birou 3"
            className="rounded-lg shadow-md"
          />
          <img
            src="/birou4.jpg"
            alt="Birou 4"
            className="rounded-lg shadow-md"
          />
          <img
            src="/birou5.jpg"
            alt="Birou 5"
            className="rounded-lg shadow-md"
          />
          <img
            src="/birou6.jpg"
            alt="Birou 6"
            className="rounded-lg shadow-md"
          />
          <img
            src="/birou7.jpg"
            alt="Birou 7"
            className="rounded-lg shadow-md"
          />
          <img
            src="/birou8.jpg"
            alt="Birou 8"
            className="rounded-lg shadow-md"
          />
        </div>
      </section>
      <section className="space-y-8">
        <h2 className="text-2xl font-semibold text-gray-800 mb-4">
          {t("servicii_si_activitati")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Feature item - colaborare instituțională */}
          <div className="flex flex-col items-start p-4 border-l-4 border-[var(--primary-color)] shadow-sm bg-white">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-2">
              {t("colaborare_instiutionala")}
            </h3>
            <p className="text-gray-600">{t("prezentare_p7")}</p>
          </div>

          {/* Feature item - dialog diplomatic */}
          <div className="flex flex-col items-start p-4 border-l-4 border-[var(--primary-color)] shadow-sm bg-white">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-2">
              {t("dialog_diplomatic")}
            </h3>
            <p className="text-gray-600">{t("prezentare_p8")}</p>
          </div>

          {/* Feature item - evenimente */}
          <div className="flex flex-col items-start p-4 border-l-4 border-[var(--primary-color)] shadow-sm bg-white">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-2">
              {t("organizare_evenimente")}
            </h3>
            <p className="text-gray-600">{t("prezentare_p9")}</p>
          </div>

          {/* Feature item - consultanță */}
          <div className="flex flex-col items-start p-4 border-l-4 border-[var(--primary-color)] shadow-sm bg-white">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-2">
              {t("consultanta")}
            </h3>
            <p className="text-gray-600">{t("prezentare_p10")}</p>
          </div>
        </div>
      </section>
      <hr className="border-t-2 border-gray-300 my-8" />
      <section className="space-y-6">
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          {t("obiective_de_dezvoltare_interna")}
        </h2>

        {/* Imagini deasupra textului */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <img
            src="/obiective1.png"
            alt="Obiectiv 1"
            className="w-full h-48 object-cover rounded-lg shadow-md"
          />
          <img
            src="/obiective2.jpg"
            alt="Obiectiv 2"
            className="w-full h-48 object-cover rounded-lg shadow-md"
          />
        </div>

        {/* Lista de obiective */}
        <div className="space-y-4">
          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-[var(--primary-color)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
              />
            </svg>
            <p className="text-gray-700">
              <strong>{t("prezentare_p11")}:</strong> {t("prezentare_p12")}
            </p>
          </div>

          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-[var(--primary-color)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
              />
            </svg>

            <p className="text-gray-700">
              <strong>{t("prezentare_p13")}:</strong> {t("prezentare_p14")}
            </p>
          </div>

          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-[var(--primary-color)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5A3.375 3.375 0 0 0 6.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0 0 15 2.25h-1.5a2.251 2.251 0 0 0-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 0 0-9-9Z"
              />
            </svg>

            <p className="text-gray-700">
              <strong>{t("prezentare_p15")}:</strong> {t("prezentare_p16")}
            </p>
          </div>

          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-[var(--primary-color)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.34 15.84c-.688-.06-1.386-.09-2.09-.09H7.5a4.5 4.5 0 1 1 0-9h.75c.704 0 1.402-.03 2.09-.09m0 9.18c.253.962.584 1.892.985 2.783.247.55.06 1.21-.463 1.511l-.657.38c-.551.318-1.26.117-1.527-.461a20.845 20.845 0 0 1-1.44-4.282m3.102.069a18.03 18.03 0 0 1-.59-4.59c0-1.586.205-3.124.59-4.59m0 9.18a23.848 23.848 0 0 1 8.835 2.535M10.34 6.66a23.847 23.847 0 0 0 8.835-2.535m0 0A23.74 23.74 0 0 0 18.795 3m.38 1.125a23.91 23.91 0 0 1 1.014 5.395m-1.014 8.855c-.118.38-.245.754-.38 1.125m.38-1.125a23.91 23.91 0 0 0 1.014-5.395m0-3.46c.495.413.811 1.035.811 1.73 0 .695-.316 1.317-.811 1.73m0-3.46a24.347 24.347 0 0 1 0 3.46"
              />
            </svg>

            <p className="text-gray-700">
              <strong>{t("prezentare_p17")}:</strong> {t("prezentare_p18")}
            </p>
          </div>

          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-[var(--primary-color)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
              />
            </svg>

            <p className="text-gray-700">
              <strong>{t("prezentare_p19")}:</strong> {t("prezentare_p20")}
            </p>
          </div>

          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-12 text-[var(--primary-color)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z"
              />
            </svg>

            <p className="text-gray-700">
              <strong>{t("prezentare_p21")}:</strong> {t("prezentare_p22")}
            </p>
          </div>
        </div>

        {/* Imagini sub text */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-6">
          <img
            src="/obiective3.jpg"
            alt="Obiectiv 3"
            className="w-full h-48 object-cover rounded-lg shadow-md"
          />
          <img
            src="/obiective4.jpg"
            alt="Obiectiv 4"
            className="w-full h-48 object-cover rounded-lg shadow-md"
          />
        </div>

        {/* Delimitare secțiuni */}
        <hr className="border-t-2 border-gray-300 my-6" />
      </section>

      <section className="space-y-6 bg-gray-50 p-6 rounded-lg">
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          {t("promovare_si_reprezentare")}
        </h2>
        <p className="text-gray-700 text-center max-w-3xl mx-auto">
          <span className="font-bold">CCIRC</span> {t("prezentare_p23")}
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-4">
          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-14 text-[var(--primary-color)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418"
              />
            </svg>

            <p className="text-gray-700">{t("prezentare_p24")}.</p>
          </div>
          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-14 text-[var(--primary-color)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 0 1 3 19.875v-6.75ZM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V8.625ZM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 0 1-1.125-1.125V4.125Z"
              />
            </svg>

            <p className="text-gray-700">{t("prezentare_p25")}.</p>
          </div>
          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-14 text-[var(--primary-color)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.26 10.147a60.438 60.438 0 0 0-.491 6.347A48.62 48.62 0 0 1 12 20.904a48.62 48.62 0 0 1 8.232-4.41 60.46 60.46 0 0 0-.491-6.347m-15.482 0a50.636 50.636 0 0 0-2.658-.813A59.906 59.906 0 0 1 12 3.493a59.903 59.903 0 0 1 10.399 5.84c-.896.248-1.783.52-2.658.814m-15.482 0A50.717 50.717 0 0 1 12 13.489a50.702 50.702 0 0 1 7.74-3.342M6.75 15a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm0 0v-3.675A55.378 55.378 0 0 1 12 8.443m-7.007 11.55A5.981 5.981 0 0 0 6.75 15.75v-1.5"
              />
            </svg>

            <p className="text-gray-700">{t("prezentare_p26")}.</p>
          </div>
          <div className="flex items-start gap-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-14 text-[var(--primary-color)]"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
              />
            </svg>

            <p className="text-gray-700">{t("prezentare_p27")}.</p>
          </div>
        </div>
      </section>

      <section className="space-y-6">
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          {t("organizare_de_evenimente")}
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col p-4 border-l-4 border-[var(--primary-color)] shadow-sm bg-white">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-2">
              {t("participare_la_forumuri")}
            </h3>
            <p className="text-gray-700">{t("prezentare_p28")}</p>
          </div>

          <div className="flex flex-col p-4 border-l-4 border-[var(--primary-color)] shadow-sm bg-white">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-2">
              {t("misiuni_economice")}
            </h3>
            <p className="text-gray-700">{t("prezentare_p29")}</p>
          </div>

          <div className="flex flex-col p-4 border-l-4 border-[var(--primary-color)] shadow-sm bg-white">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-2">
              {t("eveniment_de_suport")}
            </h3>
            <p className="text-gray-700">{t("prezentare_p30")}</p>
          </div>

          <div className="flex flex-col p-4 border-l-4 border-[var(--primary-color)] shadow-sm bg-white">
            <h3 className="text-lg font-semibold text-[var(--primary-color)] mb-2">
              {t("acordare_de_burse")}
            </h3>
            <p className="text-gray-700">{t("prezentare_p31")}</p>
          </div>
        </div>
      </section>
      <section className="space-y-6 bg-gray-50 p-8 rounded-lg shadow-md">
        {/* Titlu și descriere + imagine alăturate */}
        <div className="flex flex-col md:flex-row gap-8 items-center">
          <div className="w-full md:w-1/2 space-y-4">
            <h2 className="text-2xl font-bold text-center text-gray-800">
              {t("colaborare_cu_mediul_diplomatic")}
            </h2>
            <p className="text-gray-700">{t("prezentare_p32")}</p>
          </div>

          {/* Imaginea secțiunii */}
          <div className="w-full md:w-1/2">
            <img
              src="/diplomatic-collaboration.png"
              alt="Colaborarea cu mediul diplomatic"
              className="w-full h-48 md:h-full object-cover rounded-lg shadow-lg"
            />
          </div>
        </div>

        {/* Lista de puncte sub imagine și descriere */}
        <div className="mt-4 space-y-4">
          <ul className="list-disc list-inside text-gray-700 space-y-2">
            <li>
              <strong>{t("informarea_reciproca")}:</strong>{" "}
              {t("prezentare_p33")}
            </li>
            <li>
              <strong>{t("schimb_de_informatii")}:</strong>{" "}
              {t("prezentare_p34")}
            </li>
            <li>
              <strong>{t("promovarea_initiativelor")}:</strong>{" "}
              {t("prezentare_p35")}
            </li>
            <li>
              <strong>{t("sprijin_in_relatii")}:</strong> {t("prezentare_p36")}
            </li>
            <li>
              <strong>{t("evenimente_dedicate")}:</strong> {t("prezentare_p37")}
            </li>
            <li>
              <strong>{t("informarea_comunitatii")}:</strong>{" "}
              {t("prezentare_p38")}
            </li>
          </ul>
        </div>
      </section>
      <section className="space-y-6 p-8 rounded-lg">
        {/* Titlul și imaginea */}
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          {t("cercetare_informare_expertiza")}
        </h2>
        <p className="text-gray-700 text-center max-w-3xl mx-auto mb-4">
          <span className="font-bold">CCIRC</span> {t("prezentare_p39")}
        </p>
        {/* Activități listate în două coloane sub imagine */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mt-6">
          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-semibold text-gray-800">
                {t("elaborarea_de_evaluari")}
              </h3>
              <p className="text-gray-700">{t("prezentare_p40")}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800">
                {t("informarea_entitatilor")}
              </h3>
              <p className="text-gray-700">{t("prezentare_p41")}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800">
                {t("sustinerea_proiectelor")}
              </h3>
              <p className="text-gray-700">{t("prezentare_p42")}</p>
            </div>
          </div>

          <div className="space-y-4">
            <div>
              <h3 className="text-lg font-semibold text-gray-800">
                {t("publicatii_informative")}
              </h3>
              <p className="text-gray-700">{t("prezentare_p43")}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800">
                {t("crearea_de_programe")}
              </h3>
              <p className="text-gray-700">{t("prezentare_p44")}</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold text-gray-800">
                {t("constituirea_de_baze")}
              </h3>
              <p className="text-gray-700">{t("prezentare_p45")}</p>
            </div>
          </div>
        </div>
      </section>

      <hr className="border-t-2 border-gray-300 my-6" />
      <section className="space-y-6 bg-gray-50 p-8 rounded-lg shadow-md">
        {/* Titlu */}
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          {t("structurile_nationale")}
        </h2>

        {/* Text descriptiv */}
        <p className="text-gray-700 text-center max-w-3xl mx-auto">
          {t("prezentare_p46")}
        </p>

        {/* Imagine */}
        <div className="flex justify-center">
          <img
            src="/structuri-conducere.jpg"
            alt="Structurile de conducere"
            className="w-full object-cover rounded-lg shadow-lg"
          />
        </div>

        {/* Structuri */}
        <div className="mt-6 space-y-4">
          <div>
            <h3 className="text-lg font-semibold text-gray-800">
              {t("conferinta_nationala")}
            </h3>
            <p className="text-gray-700">{t("prezentare_p47")}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-800">
              {t("colegiul_national")}
            </h3>
            <p className="text-gray-700">{t("prezentare_p48")}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-gray-800">
              {t("biroul_permanent")}
            </h3>
            <p className="text-gray-700">{t("prezentare_p49")}</p>
          </div>
        </div>
      </section>
      <hr className="border-t-2 border-gray-300 my-6" />
      <section className="space-y-4">
        <h2 className="text-2xl font-bold text-gray-800 text-center">
          {t("certificari")}
        </h2>
        <div>
          <p className="text-gray-700 text-center mb-2 mx-auto">
            {t("prezentare_p50")} (ISO 9001:2015), {t("prezentare_p51")}{" "}
            (ISO/IEC 27001:2013)
          </p>
          <div className="flex items-center w-full gap-4">
            <img src="/certificat1.png" alt="Certificat" className="w-1/2" />
            <img src="/certificat2.png" alt="Certificat" className="w-1/2" />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="flex flex-col w-full border-2 gap-2 mt-6">
            <img src="/gdrp.png" alt="GDRP" />
            <p className="text-gray-700 text-center px-2">
              <span className="font-bold">CCIRC</span> {t("prezentare_p52")}
            </p>
          </div>
          <div className="flex flex-col w-full h-fit border-2 gap-2 mt-6">
            <img
              src="/certificari4.png"
              alt="Certificari"
              className="self-start"
            />
            <p className="text-gray-700 text-center p-2">
              <span className="font-bold">CCIRC</span> {t("prezentare_p53")}{" "}
              (RUTI)
            </p>
          </div>
        </div>
      </section>
      {/* Afilieri */}
      <section className="space-y-4">
        <h2 className="text-2xl font-semibold text-center text-gray-800">
          {t("afilieri")}
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <h4 className="text-xl font-semibold text-center mb-6 text-green-500">
              {t("afilieri_nationale")}
            </h4>
            <div className="flex items-center gap-4">
              <img
                src="/ccir.jpg"
                alt="CCIR"
                className="w-24 h-24 object-cover"
              />
              <p className="text-gray-600">
                <span className="font-bold">CCIRC</span> {t("prezentare_p54")}
              </p>
            </div>
            <img
              src="/atestat.jpg"
              alt="CCIR"
              className="my-4 w-8/12 mx-auto"
            />
            <div className="flex items-center mt-6 gap-4">
              <img
                src="/federatia.png"
                alt="CCIR"
                className="w-24 h-24 object-cover"
              />
              <p className="text-gray-600">
                <span className="font-bold">CCIRC</span> {t("prezentare_p55")}
              </p>
            </div>
          </div>
          <div>
            <h4 className="text-xl font-semibold text-center mb-6 text-green-500">
              {t("prezentare_p56")}
            </h4>
            <div className="flex flex-col items-center gap-4">
              <img src="/eurosin.jpg" alt="Eurosin" className="w-8/12" />
              <img src="/boao.png" alt="Boao" className="w-8/12" />
              <img src="/euchina.png" alt="EU-China" className="w-8/12" />
              <img
                src="/srcic.png"
                alt="Silk Road Chamber"
                className="w-8/12"
              />
              <img src="/cga.png" alt="China Go Abroad" className="w-8/12" />
            </div>
          </div>
        </div>
      </section>
      <section className="space-y-4">
        <h2 className="text-2xl font-semibold text-center text-gray-800">
          {t("parteneriate_in_rp")}
        </h2>
        <h4 className="text-xl font-semibold text-start mb-6 text-green-500">
          {t("parteneriate")}
        </h4>
        <div className="flex w-full items-center justify-center gap-4">
          <img src="/parteneriate1.png" alt="China" className="w-32" />
          <img src="/parteneriate2.png" alt="China" className="w-40" />
          <img src="/parteneriate3.jpg" alt="China" className="w-24" />
          <img src="/parteneriate4.png" alt="China" className="w-24" />
        </div>
        <h4 className="text-xl font-semibold text-start mb-6 text-green-500">
          {t("parteneriate_regionale_locale")}
        </h4>
        <div className="flex w-full items-center justify-center gap-4">
          <div>
            <img src="/parteneriate5.png" alt="China" />
            <img src="/parteneriate6.png" alt="China" />
            <img src="/parteneriate7.jpg" alt="China" />
            <img src="/parteneriate8.png" alt="China" />
            <img src="/parteneriate9.png" alt="China" />
            <img src="/parteneriate10.png" alt="China" />
            <img
              src="/parteneriate11.png"
              alt="China"
              className="w-full h-12"
            />
          </div>
          <div className="self-start">
            <img src="/parteneriate12.png" alt="China" />
            <img src="/parteneriate13.png" alt="China" />
            <img src="/parteneriate14.png" alt="China" />
            <img src="/parteneriate15.png" alt="China" />
            <img src="/parteneriate16.png" alt="China" />
            <img src="/parteneriate17.png" alt="China" />
          </div>
        </div>
      </section>
    </div>
  );
}
