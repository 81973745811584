import React, { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Hero from "./Hero";
import ColoanaStanga from "./ColoanaStanga";
import Footer from "./Footer";
import ColoanaDreapta from "./ColoanaDreapta";
import { FaArrowUp } from "react-icons/fa6";

export default function Layout() {
  const [showButton, setShowButton] = useState(false);

  // Afișează butonul când pagina este scrollată în jos
  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 300);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Funcție pentru a scrola în sus
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Hero />
      <Navbar />
      <main className="container main-page gap-4 p-4 mx-auto">
        <div className="first-div">
          <ColoanaStanga />
        </div>
        <div className="second-div">
          <Outlet />
        </div>
        <div className="third-div">
          <ColoanaDreapta />
        </div>
      </main>
      <Footer />

      {/* Buton Scroll-to-Top */}
      {showButton && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-8 hidden sm:block z-50 right-8 bg-[var(--primary-color)] text-white rounded-full p-4 shadow-lg hover:bg-[var(--primary-hover)] transition duration-300"
          aria-label="Scroll to top"
        >
          <FaArrowUp />
        </button>
      )}
    </>
  );
}
